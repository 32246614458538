import Vue from 'vue';
import axios from 'axios';
import router from '../router'

// Creamos una instancia de axios para que se puedan compartir la misma configuración en toda la aplicación

const apiUrl=Vue.prototype.$apiUrl;

const api = axios.create({
  baseURL: apiUrl,
});

// Interceptamos todas las solicitudes realizadas por axios
api.interceptors.request.use((config) => {
  
    
  // Obtenemos el token de acceso almacenado en el almacenamiento local (localStorage)
  const accessToken = localStorage.getItem('access_token');
  config.headers.Authorization = `Bearer ${accessToken}`;

  
  return config;
});


api.interceptors.response.use(
  response => {
    
    return response;
  },
  async error => {

    if(error.config.url.indexOf('/api/v1/auth')!=-1){

      return Promise.reject(error);

    }
    
    const originalRequest = error.config;
    if ((error.response.status === 440 || error.response.status === 401 || error.response.status === 500 || error.response.status === 403) && !originalRequest._retry) {
      originalRequest._retry = true;
      try {

        
        const token = localStorage.getItem('refresh_token');
        const { data } = await axios.post(apiUrl+'/auth/refreshaccesstoken', { token });
        
        

            if(!data.token){
              router.push('/user/login');
            }else{

              localStorage.setItem('access_token', data.token);
              localStorage.setItem('refresh_token', data.refresh_token);
              return api(originalRequest);
            }
        
      } catch (error) {
        // Manejar errores al solicitar un nuevo token de acceso
        
        console.error(error);
       // Vue.prototype.$router.push('/user/login');
        router.push('/user/login');

        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);



export default api;
